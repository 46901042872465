import React from 'react';
import warehouseJpg from 'assets/images/about_us/printivity-production-warehouse.jpg';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const MainImage = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[586px] 3xl:h-[836px] flex items-center justify-center')}>
    <div className="flex justify-center px-4 md:px-8 xl:px-20 mb-20" id="main-img">
      <div
        className="h-[586px] 3xl:h-[836px] w-full rounded-3xl"
        style={{
          backgroundImage: `linear-gradient(0deg, transparent, rgba(0, 0, 0, 0.2)), url(${warehouseJpg})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      />
    </div>
  </LazyLoad>
);

export default MainImage;
