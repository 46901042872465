import sliderImg1 from 'assets/images/OurStory/slider_1.jpg';
import sliderImg2 from 'assets/images/OurStory/slider_2.jpg';
import sliderImg3 from 'assets/images/OurStory/slider_3.jpg';
import sliderImg4 from 'assets/images/OurStory/slider_4.jpg';
import sliderImg5 from 'assets/images/OurStory/slider_5.jpg';
import sliderImg6 from 'assets/images/OurStory/slider_6.jpg';
import sliderImg7 from 'assets/images/OurStory/slider_7.jpg';
import sliderImg8 from 'assets/images/OurStory/slider_8.jpg';
import sliderImg9 from 'assets/images/OurStory/slider_9.jpg';
import sliderImg10 from 'assets/images/OurStory/slider_10.jpg';
import sliderImg11 from 'assets/images/OurStory/slider_11.jpg';

export const upperCarouselImages = [
  {
    url: sliderImg1,
    alt: 'Image 1',
    title: 'Image1',
  },
  {
    url: sliderImg2,
    alt: 'Image 2',
    title: 'Image2',
  },
  {
    url: sliderImg3,
    alt: 'Image 3',
    title: 'Image3',
  },
  {
    url: sliderImg4,
    alt: 'Image4',
    title: 'Image4',
  },
  {
    url: sliderImg5,
    alt: 'Image 5',
    title: 'Image5',
  },
];

export const lowerCarouselImages = [
  {
    url: sliderImg6,
    alt: 'Image 6',
    title: 'Image6',
  },
  {
    url: sliderImg7,
    alt: 'Image 7',
    title: 'Image7',
  },
  {
    url: sliderImg8,
    alt: 'Image 8',
    title: 'Image8',
  },
  {
    url: sliderImg9,
    alt: 'Image 9',
    title: 'Image9',
  },
  {
    url: sliderImg10,
    alt: 'Image 10',
    title: 'Image10',
  },
  {
    url: sliderImg11,
    alt: 'Image 11',
    title: 'Image11',
  },
];
