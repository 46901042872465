import React from 'react';
import printivityLogo from 'assets/images/printivity-logo.png';
import IconArrowDown from 'styleguide/icons/IconArrowDown';
import useSmoothScroll from 'utils/hooks/useSmoothScroll';
import { FadeIn } from 'styleguide/components/FadeIn/FadeIn';

const Header = () => {
  const { scrollToElement } = useSmoothScroll();

  return (
    <div className="w-full text-center h-[calc(100vh-60px)] md:h-[calc(100vh-80px)] flex justify-center items-center px-4">
      <FadeIn>
        <div className="max-w-[600px] mx-auto grid place-items-center">
          <img src={printivityLogo} className="block h-20 w-auto mx-auto mb-8" alt="Printivity" />
          <h1 className="text-5xl sm:text-6xl font-hvMedium font-medium mb-8">The Printivity Story.</h1>
          <p className="mb-11 mt-0">
            A long time ago, we needed to get something printed. But what we got was poor quality and bad
            customer service. That experience made us realize that things could be better, so we decided to do
            it ourselves.
          </p>
          <div
            className="bg-gray-50 rounded-full h-10 w-10 flex justify-center items-center"
            onClick={() => scrollToElement('main-img', 100)}
            onKeyDown={() => scrollToElement('main-img', 100)}
            tabIndex={0}
            role="button"
          >
            <IconArrowDown color="dark" className="!h-2 !w-2 cursor-pointer" />
          </div>
        </div>
      </FadeIn>
    </div>
  );
};

export default Header;
