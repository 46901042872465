import React from 'react';
import ButtonAsNativeLink from 'styleguide/components/Button/ButtonAsNativeLink';
import { products } from 'bundles/App/routes';
import { FadeIn } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const MakingSomethingReal = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[520px] flex items-center justify-center')}>
    <div className="mt-[112px] mb-[68px] px-4 sm:px-8 2xl:px-[100px]">
      <FadeIn>
        <div className="bg-neutral-100 rounded-3xl py-32 text-center">
          <h4 className="mb-8 sm:text-6xl text-5xl font-hvMedium">
            Make Something <br /> Real.
          </h4>
          <p className="mb-8">
            Don&apos;t just wait for opportunities. Create them. <br /> Market and promote your business with
            online custom printing.
          </p>
          <ButtonAsNativeLink buttonType="anchor" color="dark" target={products}>
            Get Started Now
          </ButtonAsNativeLink>
        </div>
      </FadeIn>
    </div>
  </LazyLoad>
);

export default MakingSomethingReal;
