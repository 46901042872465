import * as React from 'react';
import Footer from 'styleguide/components/Footer/Footer';
import Meta from 'styleguide/components/Meta/Meta';
import Header from './Header/Header';
import MainImage from './MainImage/MainImage';
import WeArePrintivity from './WeArePrintivity/WeArePrintivity';
import PrintMadeEasy from './PrintMadeEasy/PrintMadeEasy';
import MakingSomethingReal from './MakingSomethingReal/MakingSomethingReal';
import MakingRealDifference from './MakingRealDifference/MakingRealDifference';
import ProductCarousel from './ProductCarousel/ProductCarousel';
import CompanyHistory from './CompanyHistory/CompanyHistory';
import FounderQuote from './FounderQuote/FounderQuote';
import GreatIndividuals from './GreatIndividuals/GreatIndividuals';

const OurStoryPage = () => (
  <>
    <Meta
      title="The Printivity Story | About Us"
      description={
        "Learn about us and read the Printivity story - who we are and why we're the best online " +
        'printing company.'
      }
      keywords="our story printivity, about us printivity"
      canonical="https://www.printivity.com/our-story"
    />
    <Header />
    <MainImage />
    <CompanyHistory />
    <ProductCarousel />
    <PrintMadeEasy />
    <FounderQuote />
    <GreatIndividuals />
    <MakingRealDifference />
    <WeArePrintivity />
    <MakingSomethingReal />
    <Footer />
  </>
);
export default OurStoryPage;
