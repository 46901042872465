import React from 'react';
import Image from 'styleguide/components/Image/Image';
import img1 from 'assets/images/OurStory/garden.jpg';
import img2 from 'assets/images/OurStory/package.jpg';
import { FadeIn, FadeInStagger } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const MakeARealDifferenceDesc = () => (
  <div className="bg-neutral-100 rounded-3xl px-4 sm:px-10 lg:px-12 py-8 sm:py-10 lg:py-12 3xl:py-16 mt-6 h-[calc(100%-1.5rem)]">
    <h4 className="mb-8 text-4xl font-hvMedium">Making A Real Difference</h4>
    <p className="mb-8">
      We’re all hard workers. But we also know that we wouldn’t be where we are today without all the help
      that we’ve received throughout the years.
    </p>
    <p>It means something real to not just be part of a community, but to grow and better it.</p>
  </div>
);

const MakingRealDifference = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[890px] flex items-center justify-center')}>
    <FadeInStagger>
      <div className="flex flex-col lg:flex-row align-stretch lg:gap-x-6 px-4 sm:px-8 2xl:px-[100px] py-20 h-[calc(100% - 1.5rem)]">
        <FadeIn className="lg:w-[145%] h-auto">
          <Image className="w-full h-full rounded-3xl mr-6 object-cover" alt="Mgx copy" url={img1} lazyload />
        </FadeIn>
        <div className="flex flex-col-reverse lg:flex-col h-auto">
          <FadeIn>
            <Image
              className="h-full rounded-3xl mt-6 lg:mt-0 w-full object-cover"
              alt="Mgx copy"
              url={img2}
              lazyload
            />
          </FadeIn>
          <FadeIn className="h-full">
            <MakeARealDifferenceDesc />
          </FadeIn>
        </div>
      </div>
    </FadeInStagger>
  </LazyLoad>
);

export default MakingRealDifference;
