import React from 'react';
import { FadeIn, FadeInStagger } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const CompanyHistory = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[320px] flex items-center justify-center')}>
    <div className="max-w-[760px] mx-auto text-center py-12 mb-28 px-4">
      <FadeInStagger>
        <FadeIn>
          <div className="text-neutral-700 font-medium font-hvMedium text-sm mb-8">
            WE WEREN’T ALWAYS PRINTERS
          </div>
        </FadeIn>
        <FadeIn>
          <h3 className="md:text-5xl text-4xl font-hvMedium mb-8">
            So we built a company that we would be proud of.
          </h3>
        </FadeIn>
        <FadeIn>
          <p className="text-base max-w-[600px] mx-auto">
            Great products, great service, and great prices. Easy to order from. Plus the fastest turnarounds
            in the country. We would stand behind every promise that we would make to a customer. Even
            delivery dates. Our team would care about our customers. And our customers would depend on us to
            grow their businesses and to tell their stories.
          </p>
        </FadeIn>
      </FadeInStagger>
    </div>
  </LazyLoad>
);

export default CompanyHistory;
