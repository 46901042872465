import { StepProps } from 'styleguide/components/InformationWithSteps/InformationWithSteps';
import {
  IconChooseDesign,
  IconSelectTemplate,
  IconDesign,
  IconApproveDesign,
  IconOne,
  IconTwo,
  IconThree,
  IconFour,
} from 'styleguide/icons';
import img1 from 'assets/images/DesignServices/LatestWork-004.png';
import img2 from 'assets/images/DesignServices/LatestWork-005.png';
import img3 from 'assets/images/DesignServices/LatestWork-006.png';
import img4 from 'assets/images/DesignServices/kraft_business_cards.jpg';
import img5 from 'assets/images/DesignServices/LatestWork-008.png';
import img6 from 'assets/images/DesignServices/LatestWork-009.png';
import img7 from 'assets/images/DesignServices/LatestWork-010.png';
import img8 from 'assets/images/DesignServices/LatestWork-011.png';
import img9 from 'assets/images/DesignServices/LatestWork-012.png';
import img10 from 'assets/images/DesignServices/LatestWork-013.png';
import img11 from 'assets/images/DesignServices/LatestWork-014.png';
import img12 from 'assets/images/DesignServices/LatestWork-015.png';
import img13 from 'assets/images/DesignServices/LatestWork-016.png';
import colorCopiesImage from 'assets/images/DesignServices/color-copies.png';
import bookCoversImage from 'assets/images/DesignServices/book-covers.png';
import miniPostersImage from 'assets/images/DesignServices/mini-posters.png';
import postCardsImage from 'assets/images/DesignServices/postcards.png';
import businessCardsImage from 'assets/images/DesignServices/business-cards.png';
import booksImg from 'assets/images/DesignServices/books.png';
import foldersImage from 'assets/images/DesignServices/folders.png';
import logoImage from 'assets/images/DesignServices/logo.png';
import shippingBoxesImg from 'assets/images/DesignServices/shippingBoxes.png';
import { Faq } from 'bundles/App/pages/HelpCenter/types';

export const designServiceSteps: StepProps[] = [
  {
    Icon: IconChooseDesign,
    StepNumberIcon: IconOne,
    title: 'Describe',
    description:
      'Fill out our online design form – we’ll use the information you provide to estimate a price and timeline.',
  },
  {
    Icon: IconSelectTemplate,
    StepNumberIcon: IconTwo,
    title: 'View',
    description:
      'Get your design – we’ll deliver something you’re bound to love. If not, our design team offers free, unlimited revisions.',
  },
  {
    Icon: IconDesign,
    StepNumberIcon: IconThree,
    title: 'Refine',
    description: 'Decide on your materials and quantity for the final printed product.',
  },
  {
    Icon: IconApproveDesign,
    StepNumberIcon: IconFour,
    title: 'Relax',
    description:
      'Let Printivity handle the rest! We’ll start printing your order and let you know when it’s been shipped.',
  },
];

export const designRequestFaqs: Faq[] = [
  {
    question: 'What does the price include?',
    answer: `The quoted price includes a high-resolution PDF file formatted perfectly for print, additional file formats that you request upon ordering, and 
    unlimited revisions, all within a competitive turnaround time. You’ll have a single designer dedicated to your project who will make sure that the final
      product matches your vision. On top of that, if you choose to print your files with us, you can count on a fast and easy ordering process.`,
  },
  {
    question: 'What factors will affect my design schedule?',
    answer: `To ensure that you have a smooth and uninterrupted design process, we recommend that you:
                <ul>
                    <li>Submit as much information and resources as possible up front when submitting the online request form.</li>
                    <li>This will help to reduce the number of questions our designer has,  as well as the number of revisions required for your project.</li>
                    <li>Upload reference images or let us know which brands and styles you like.</li>
                    <li>Look out for our emails with updates on your project.</li>
                </ul>`,
  },
  {
    question: 'How long does the design process take? ',
    answer:
      'Most simple designs are completed in as little as two business days. For more complex projects, extra time may be required. However, you will know ahead of time, so there are no unexpected delays on your order.',
  },

  {
    question: 'Who is the design team?',
    answer:
      'Most simple designs are completed in as little as two business days. For revisions or more complex projects, extra time may be required. However, you will know ahead of time, so there will be no unexpected delays with your order.',
  },
  {
    question: 'How does the design process work? And what if I’m not happy with the design?',
    answer:
      'Fill out the form above with your design instructions. We’ll process your request and ask for any additional files. The team will also ask you more about your business because we want to craft a design that aligns with your brand. Once the design is completed, we will send the file to you for review. Our team can make adjustments and tweaks if you’re not totally satisfied – we offer unlimited revisions!<br/><br/>' +
      'We’re so confident that you’ll be happy with what our team comes up with, that if you’re not satisfied, you won’t be charged any design fees. Approximately 99% of our customers are delighted with what we create',
  },
  {
    question: 'How do I get my product printed?',
    answer: `Once you’ve received your print-ready design file, you can order your printed products directly from Printivity’s website! Or, work with our Customer Service team for help in choosing the perfect product details for you.
    `,
  },
  {
    question: 'How quickly will I receive my printed products?',
    answer: `It depends on several factors, such as the size of your order, where you’re located, and the type of order. A set of business cards, for example, can be printed overnight, but more complex, large-scale printed orders may take a little longer.\n 
    Get in touch with Printivity to get a rough timescale for your order.`,
  },
  {
    question: 'Can I get my products delivered urgently?',
    answer: `Yes, Printivity offers a rush critical service where we’ll put your project to the head of the line. We can print in as little as 24 hours on most products.\n 
    Double-check first to make sure your order is eligible for the rush critical service.`,
  },
  {
    question: 'Do you do custom orders?',
    answer:
      'Yes! We are ready to tackle any design instructions, including <a href="/boxes/shipping-boxes">custom shipping boxes</a>. Just get in touch with us if you need something unconventional. Our design team can make adjustments to fulfill the needs of any business.',
  },
  {
    question: "Why should I choose Printivity's design services?",
    answer:
      "We've been in the print business for years. We've helped thousands of customers create new designs or make corrections so that their files will be ready to print, including files that were designed by professional graphic designers! The problem is that most graphic designers only have experience in designing for digital media - designing for print media is quite different. Our design team has direct print experience. You can let us worry about things like bleeds, gutters, safety margins, CMYK color spaces, minimum line widths, and more. You'll be sure to get a design that will look just as good on paper as it did on the screen.",
  },
];

export const designServicesImages = [
  {
    url: img1,
    alt: 'Latest work 1',
    title: 'Latest work 1',
  },
  {
    url: img2,
    alt: 'Latest work 2',
    title: 'Latest work 2',
  },
  {
    url: img3,
    alt: 'Latest work 3',
    title: 'Latest work 3',
  },
  {
    url: img4,
    alt: 'Latest work 4',
    title: 'Latest work 4',
  },
  {
    url: img5,
    alt: 'Latest work 5',
    title: 'Latest work 5',
  },
  {
    url: img6,
    alt: 'Latest work 6',
    title: 'Latest work 6',
  },
  {
    url: img7,
    alt: 'Latest work 7',
    title: 'Latest work 7',
  },
  {
    url: img8,
    alt: 'Latest work 8',
    title: 'Latest work 8',
  },
  {
    url: img9,
    alt: 'Latest work 9',
    title: 'Latest work 9',
  },
  {
    url: img10,
    alt: 'Latest work 10',
    title: 'Latest work 10',
  },
  {
    url: img11,
    alt: 'Latest work 11',
    title: 'Latest work 11',
  },
  {
    url: img12,
    alt: 'Latest work 12',
    title: 'Latest work 12',
  },
  {
    url: img13,
    alt: 'Latest work 13',
    title: 'Latest work 13',
  },
];

export const designServicesWhyPrintivity: string = `
<p style="font-weight:500;max-width:800px;">
Printivity never compromises when it comes to quality. Our graphic design services provide not
only immediate results but also help you in achieving your key business objectives.
</p>
<p style="font-weight:500;margin-bottom: 40px;">
Here’s why Printivity is the best printing company to have by your side.
</p>
<div style="display:flex;flex-wrap:wrap;font-weight:300;">
<div style="flex-basis: 48%;min-width: 320px;flex-grow: 1;margin-bottom: 24px;margin-right:32px;">
  <h3 style="font-weight: 700;font-size: 24px;line-height: 29px;text-transform: uppercase; ">
    <strong>Simplicity in Design and Printing</strong>
  </h3>
  <p style="margin-top: 8px;">
    At Printivity, we believe in keeping things simple. You don’t need to be a Photoshop master, and
    you most certainly don’t need to invest in an in-house design team to stand out.
  </p>
  <p>
    Make a lasting impression while keeping things simple. Our graphic design printing team will
    handle all the complexities of the design and printing process.<br/>
    All you need to do is pour the soul of your brand into the project, and we’ll do the rest.</p>
</div>
<div style="flex-basis: 48%;min-width: 320px;flex-grow: 1;margin-bottom: 24px;">
<h3 style="font-weight: 700;font-size: 24px;line-height: 29px;text-transform: uppercase;">
  <strong>Speedy Results</strong>
</h3>
<p style="margin-top: 8px;">
  Speed is important to businesses. Our streamlined approach to design and printing services means
  we can work to a tight deadline.
</p>
<p>
  Whether you have a hard deadline for a brand relaunch or the business conference of your life
  coming up, Printivity can stick to your timeline without delay.
</p>
</div>
<div style="flex-basis: 48%;min-width: 320px;flex-grow: 1;margin-bottom: 24px;margin-right:32px;">
<h3 style="font-weight: 700;font-size: 24px;line-height: 29px;text-transform: uppercase;">
  <strong>End-to-End Support</strong>
</h3>
<p style="margin-top: 8px;">
  Never feel like you’re out of the loop. You will be guided by our expert team every step of the
  way to ensure that the results meet your initial specifications.
</p>
<p>For real end-to-end support, choose Printivity.</p>
</div>
<div style="flex-basis: 48%;min-width: 320px;flex-grow: 1;margin-bottom: 24px;">
<h3 style="font-weight: 700;font-size: 24px;line-height: 29px;text-transform: uppercase;">
  <strong>Perfect Results Every Time</strong>
</h3>
<p style="margin-top: 8px;">
  Whether you’re tackling a large banner for a corporate event or printing a new set of business
  cards, expect perfection every time.
<br/>
  A member of the team will go over your files to ensure that they are the best they can be. If we
  believe that there are opportunities to increase design quality, we’ll tell you about it.
<br/>
  Plus, our satisfaction guarantee means that if you are not 100% satisfied with our work, we will give
  you a full refund.
</p>
</div>
<div style="flex-basis: 50%;min-width: 320px;flex-grow: 1;">
<h3 style="font-weight: 700;font-size: 24px;line-height: 29px;text-transform: uppercase;">
  <strong>Prepared for Any Possibility</strong>
</h3>
<p style="margin-top: 8px;">
  Are you on a tight deadline and need to take advantage of overnight print services? Do you have
  any final changes to your design?
</p>
<p>
  Printivity is prepared for all eventualities because we are committed to helping you get the
  results you expect.
</p>
</div>
</div>
`;

export const pricingCards = [
  {
    image: {
      url: businessCardsImage,
      alt: 'Business Cards',
    },
    title: 'Business Cards',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush And Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$120',
      unit: 'design',
    },
  },
  {
    image: {
      url: postCardsImage,
      alt: 'Postcards',
    },
    title: 'Postcards',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush And Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$150',
      unit: 'card',
    },
  },
  {
    image: {
      url: booksImg,
      alt: 'Books and Booklets',
    },
    title: 'Books/Booklets',
    turnaround: 'Receive a design preview in just 4 days',
    info: ['✓ Up to 3 Free Revisions', '✓ Includes Print-Ready Design Files', '✓ Ask About Our Rush Options'],
    bubble: {
      linkText: 'Get a Quote within 24 hours!',
      linkUrl: '',
    },
  },
  {
    image: {
      url: bookCoversImage,
      alt: 'Book Covers',
    },
    title: 'Book Covers',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush And Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$250',
      unit: 'cover',
    },
  },
  {
    image: {
      url: foldersImage,
      alt: 'Folders',
    },
    title: 'Folders',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush and Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$240',
      unit: 'Design',
    },
  },
  {
    image: {
      url: colorCopiesImage,
      alt: 'Color Copies',
    },
    title: 'Color Copies',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush And Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$80',
      unit: 'page',
    },
  },
  {
    image: {
      url: logoImage,
      alt: 'Logo',
    },
    title: 'Logo',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush and Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$150',
      unit: 'Design',
    },
  },
  {
    image: {
      url: shippingBoxesImg,
      alt: 'Shipping Boxes',
    },
    title: 'Shipping Boxes',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush and Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$350',
      unit: 'Design',
    },
  },
  {
    image: {
      url: miniPostersImage,
      alt: 'Mini Posters',
    },
    title: 'Mini Posters',
    turnaround: 'Standard 4 Day Turnaround',
    info: [
      '✓ Includes Unlimited Revisions',
      '✓ Includes Print-Ready Design Files',
      '✓ Rush And Next Day Turnarounds Available',
    ],
    bubble: {
      price: '$120',
      unit: 'poster',
    },
  },
];
