import React from 'react';
import { FadeIn } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const FounderQuote = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[448px] flex items-center justify-center')}>
    <div className="text-center py-32 max-w-[395px] mx-auto">
      <FadeIn>
        <blockquote>
          <h5 className="font-medium font-hvMedium text-2xl mb-9">
            “Buying print is too hard and costs too much. You don’t know what you’re going to get or when
            you’re going to get it. We’re going to change that.”
          </h5>
        </blockquote>
        <p className="text-gray-500">– Lawrence Chou, Founder and CEO</p>
      </FadeIn>
    </div>
  </LazyLoad>
);

export default FounderQuote;
