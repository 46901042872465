import { useCallback } from 'react';

const useSmoothScroll = () => {
  const scrollToElement = useCallback((elementId: string, offset: number = 0) => {
    const element = document.getElementById(elementId);
    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scroll({ top: y, behavior: 'smooth' });
    }
  }, []);

  return { scrollToElement };
};

export default useSmoothScroll;
