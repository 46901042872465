import React from 'react';
import Slider from '../Slider/Slider';
import { upperCarouselImages, lowerCarouselImages } from './carouselImageData';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const WeArePrintivity = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[1032px] flex items-center justify-center')}>
    <h2 className="md:text-5xl text-4xl font-hvMedium mb-12 mt-40 text-center">We are Printivity.</h2>
    <Slider
      carouselImages={upperCarouselImages}
      imgWidth={420}
      imgHeight={400}
      wrapperClassName="mb-6"
      sliderElClassName="w-[444px] h-[400px]"
      slideCount={6}
      innerPadding={24}
    />
    <Slider
      marginLeft="-ml-[210px]"
      carouselImages={lowerCarouselImages}
      imgWidth={420}
      imgHeight={400}
      innerPadding={24}
      slideCount={6}
      sliderElClassName="!w-[444px] h-[400px]"
    />
  </LazyLoad>
);

export default WeArePrintivity;
