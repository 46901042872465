import React from 'react';
import Slider from '../Slider/Slider';
import { designServicesImages } from 'bundles/App/pages/service/DesignServices/designServicesData';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const ProductCarousel = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[408px] flex items-center justify-center')}>
    <Slider
      carouselImages={designServicesImages.slice(0, 13)}
      imgHeight={338}
      imgWidth={370}
      sliderHeightClassName="!h-[338px]"
      sliderElClassName="w-[386px] h-[338px]"
      wrapperClassName="py-6 my-8"
      slideCount={13}
      innerPadding={16}
    />
  </LazyLoad>
);

export default ProductCarousel;
