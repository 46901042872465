import React from 'react';
import { IconArrowFull } from 'styleguide/icons';
import { FadeIn, FadeInStagger } from 'styleguide/components/FadeIn/FadeIn';
import LazyLoad from 'react-lazyload';
import LoaderWrapper from 'styleguide/components/LoaderWrapper/LoaderWrapper';

const GreatIndividuals = () => (
  <LazyLoad once placeholder={LoaderWrapper('h-[600px] flex items-center justify-center')}>
    <FadeInStagger>
      <div className="flex flex-col md:flex-row justify-between px-4 sm:px-8 2xl:px-[100px] py-20">
        <FadeIn>
          <IconArrowFull className="!w-[110px] !h-[42px] mb-10 md:mb-0" />
        </FadeIn>
        <FadeIn>
          <div className="flex flex-col">
            <h3 className="text-4xl sm:text-5xl font-hvMedium mb-14">
              So we found great individuals. <br /> And we started giving back.
            </h3>
            <div className="grid gap-x-6 gap-y-9 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              <p className="lg:max-w-[290px] m-0">
                And we built a culture around these individuals. A culture of betterment. Meaning that no
                matter where we came from, or who we are now, we can become something more.
              </p>
              <p className="lg:max-w-[290px] m-0">
                We ask all Printivity employees to volunteer at least 20 hours for a cause that they are
                passionate about. And every year, over 90% of us do.
              </p>
              <p className="lg:max-w-[290px] m-0">
                We’re all hard workers. But we also know that we wouldn’t be where we are today without all
                the help that we’ve received throughout the years. It means something real to not just be part
                of a community, but to grow and better it.
              </p>
            </div>
          </div>
        </FadeIn>
      </div>
    </FadeInStagger>
  </LazyLoad>
);

export default GreatIndividuals;
